import { Box, Text, VStack, Button, Collapse } from "@chakra-ui/react";
import React from "react";

const FAQ = () => {
  return (
    <VStack spacing={6} align="start">
      {/* <Heading as="h1" size="xl" color="orange.500">
        Frequently Asked Questions
      </Heading> */}
      {questionsAndAnswers.map((qa, index) => (
        <FAQItem key={index} question={qa.question} answer={qa.answer} />
      ))}
    </VStack>
  );
};

const FAQItem = ({ question, answer }) => {
  const [show, setShow] = React.useState(false);

  const handleToggle = () => {
    setShow(!show);
  };

  return (
    <Box width="100%">
      <Button
        onClick={handleToggle}
        variant="ghost"
        width="100%"
        textAlign="left"
        justifyContent="start"
        rightIcon={show ? "-" : "+"}
      >
        {question}
      </Button>
      <Collapse in={show}>
        <Text mt={4}>{answer}</Text>
      </Collapse>
    </Box>
  );
};

// What is this website for?
// > I found myself constantly searching Google for whether my dog could eat various human foods, like blueberries, watermelon, carrots, and the like. So I figured rather than spend 2 seconds Googling, I could spend several weeks to create an AI that would do the exact same thing, but using LLMs
// Why are you doing this?
// > The same reason we went to the moon: not because it’s easy, but because it’s hard!
// So you’re a wrapper around ChatGPT?
// > …yes. Shh don’t tell anyone
// Will this work for my pet cat?
// > Our research team is actively investigating this. *Show a picture of Farfel as a scientist
// How do you make money?
// > We are investigating a variety of monetization strategies, including a “premium” version. As well, we are looking into using the trove of high quality data we are collecting to train a dog-food-centric LLM, which of course, OpenAI will pay us millions for, probably.
// That seems like a bad strategy
// > Yeah of course it’s a bad strategy, I was being sarcastic
// What is your data protection policy?
// > Sir, we are a website where you ask whether your dog can eat things. Please don’t put your SSN into the chat box.

const questionsAndAnswers = [
  {
    question: "What is this website for?",
    answer:
      "I found myself constantly searching Google for whether my dog could eat various human foods, like blueberries, watermelon, carrots, and the like. So I figured rather than spend 2 seconds Googling, I could spend several weeks to create an AI that would do the exact same thing, but using LLMs",
  },
  {
    question: "So you're a wrapper around ChatGPT?",
    answer: "…yes. Shh don't tell anyone",
  },
  {
    question: "Why are you doing this?",
    answer:
      "The same reason we went to the moon: not because it's easy, but because it's hard!",
  },
  // TODO: Add picture of farfel looking like a scientist that pops up when a user
  // hovers over "our research team..."
  {
    question: "Will this work for my pet cat?",
    answer:
      "Our research team is actively investigating this. We expect to publish our findings in several relevant academic journals soon.",
  },
  {
    question: "How do you make money?",
    answer:
      'We are investigating a variety of monetization strategies, including a "premium" subscription, which is the same as the current version, but says "premium" at the top left. As well, we are looking into using the trove of high quality data we are collecting to train a dog-food-centric LLM, which of course, OpenAI will pay us millions for, probably.',
  },
  {
    question: "That seems like a bad strategy.",
    answer: "Yeah of course it's a bad strategy, I was being sarcastic.",
  },
  {
    question: "What is your data protection policy?",
    answer:
      "This is a website where you ask whether your dog can eat things. Please don't put your SSN into the question box.",
  },
];

export default FAQ;
