// FarfelImage.js
import React, { useEffect, useState } from "react";
import imagePaths from "../assets/imagesPaths.json";

const getImageUrls = (responseType) => {
  return imagePaths[responseType].map((name) =>
    require(`../images/${responseType.toLowerCase()}/${name}`)
  );
};

const FarfelImage = ({ response }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (response && ["YES", "NO", "UNSURE"].includes(response)) {
      const responseImages = getImageUrls(response);
      const randomImage =
        responseImages[Math.floor(Math.random() * responseImages.length)];
      setImageUrl(randomImage);
    }
  }, [response]);
  if (!response || !["YES", "NO", "UNSURE"].includes(response) || !imageUrl) {
    return null; // or render some default image or message
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={imageUrl}
        alt={response}
        style={{ maxHeight: "300px", borderRadius: "10px" }}
      />
    </div>
    // <Box boxSize="sm">
    //   <Image src={imageUrl} alt={response} />
    // </Box>
  );
};

export default FarfelImage;
