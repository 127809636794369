import React from "react";
import ReactDOM from "react-dom";
import {
  ChakraProvider,
  VStack,
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import App from "./App";
import About from "./pages/About";
import FAQ from "./pages/FAQ"; // Make sure the path is correct
import ReactGA from "react-ga4";

ReactGA.initialize("G-MKP7YP7LVM");

const Root = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <ChakraProvider>
      <Router>
        <VStack
          minHeight="100vh"
          // justifyContent="space-between"
          direction="column"
        >
          <Box bg="gray.50" p={5} w="100%">
            <Container>
              <Flex align="center" justify="space-between">
                <Link to="/">
                  <Heading as="h2" size="md" color="orange.500">
                    CMDETF?
                  </Heading>
                </Link>
                <Box>
                  <Box as="span" pr={4}>
                    <Button
                      onClick={onOpen}
                      variant="outline"
                      colorScheme="orange"
                    >
                      FAQ
                    </Button>
                  </Box>
                  <Button
                    as={Link}
                    to="/about"
                    colorScheme="orange"
                    variant="ghost"
                  >
                    About
                  </Button>
                </Box>
              </Flex>
            </Container>
          </Box>
          <Container flex="1">
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </Container>
          <Box mt={10} p={5} bg="gray.100" textAlign="center">
            <Text fontSize="xs">
              Disclaimer: The results are generated by a language model which
              can sometimes make errors or "hallucinate". Always double-check
              the information provided and do not rely on this site as your sole
              source of information. Consult with your vet on your dog's unique
              dietary needs. CanMyDogEatThisFood.com takes no responsibility for
              any bodily harm that may or may not have occurred to pets
              following the use of this website.
            </Text>
            <Text fontSize="sm">
              © 2023{" "}
              <ChakraLink href="https://benjaminlevy.ca" target="_blank">
                Benjamin Levy
              </ChakraLink>
            </Text>
          </Box>
        </VStack>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader color="orange.500">
              Frequently Asked Questions
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FAQ />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Router>
    </ChakraProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

// ReactDOM.render(
//   <React.StrictMode>
//     <Root />
//   </React.StrictMode>,
//   document.getElementById("root")
// );
