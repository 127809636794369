import React from "react";
import {
  Container,
  Text,
  Heading,
  Image,
  HStack,
  VStack,
  Link,
} from "@chakra-ui/react";
import imageUrl from "../images/about.jpeg";

const About = () => {
  return (
    <Container maxW="container.lg">
      <Heading as="h1" size="2xl" my={5} color="orange.500">
        About the Creator
      </Heading>
      <VStack>
        <HStack>
          <Image
            src={imageUrl} // Update this path with your image URL
            borderRadius="full"
            boxSize="150px"
            objectFit="cover"
            mr={[0, 5]}
            mb={[5, 0]}
          />
          <Text>
            👋 Hey there! I'm Ben. I'm a full-stack Data Scientist who loves
            making playful, interactive data and ML apps.
          </Text>
        </HStack>
        <Text>
          You can see some more of my work{" "}
          <Link
            color="orange.500"
            href="https://www.benjaminlevy.ca/projects/"
            target="_blank"
          >
            here.
          </Link>
        </Text>
      </VStack>
      {/* <Box d="flex" flexDirection={["column", "row"]} alignItems="center"> */}
      {/* </Box> */}
    </Container>
  );
};

export default About;
