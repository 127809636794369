import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Heading,
  Input,
  Text,
  LinkBox,
  LinkOverlay,
  Flex,
  CircularProgress,
  VStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon, SearchIcon } from "@chakra-ui/icons";
import FarfelImage from "./components/FarfelImage";

// const API_URL = process.env.API_URL || "http://localhost:8000/foodsafety";
// console.log(process.env);
// console.log(API_URL);
const API_URL =
  "https://majestic-congaree-23834-5f6dc69673b7.herokuapp.com/foodsafety";
// const API_URL = "http://localhost:8000/foodsafety";

const App = () => {
  const [food, setFood] = useState("");
  const [canEatStr, setCanEatStr] = useState(null);
  const [message, setMessage] = useState("");
  const [source, setSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getFoodSafety = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ food }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const { canEatStr, message, source } = await response.json();
      setCanEatStr(canEatStr);
      setMessage(message);
      setSource(source);
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VStack justifyContent="space-between" direction="column">
      <Container>
        <Heading
          as="h1"
          size="2xl"
          textAlign="center"
          my={5}
          color="orange.500"
        >
          Can My Dog Eat This Food?
        </Heading>
        <form onSubmit={getFoodSafety}>
          <Flex mb={3}>
            <Input
              type="text"
              value={food}
              onChange={(e) => setFood(e.target.value)}
              placeholder="Enter food here..."
              mr={3}
            />
            <Button
              leftIcon={<SearchIcon />}
              colorScheme="orange"
              type="submit"
              isLoading={isLoading}
            >
              Check
            </Button>
          </Flex>
        </form>
        {isLoading ? (
          <Box display="flex" justifyContent="center" p={5}>
            <CircularProgress isIndeterminate color="orange.500" />
          </Box>
        ) : (
          <VStack textAlign={canEatStr !== null ? "center" : "left"} p={5}>
            <Heading
              as="h2"
              size="xl"
              color={
                canEatStr === "YES"
                  ? "green.500"
                  : canEatStr === "NO"
                  ? "red.500"
                  : "orange.500"
              }
              mb={3}
            >
              {canEatStr}
            </Heading>
            <FarfelImage response={canEatStr} />
            <Text mb={3}>{message}</Text>
            {source !== "" && (
              <LinkBox borderWidth="1px" borderRadius="md" padding="5" my={3}>
                <LinkOverlay href={source} isExternal>
                  Source: {getBaseDomainNameFromUrl(source)}
                  <ExternalLinkIcon mx="2px" />
                </LinkOverlay>
              </LinkBox>
            )}
          </VStack>
        )}
      </Container>
    </VStack>
  );
};

export default App;

const getBaseDomainNameFromUrl = (url) => {
  try {
    const urlObj = new URL(url);
    const domain = urlObj.hostname;
    const baseDomain = domain.replace("www.", "");
    return baseDomain;
  } catch {
    return url;
  }
};
